import router from "next/router";
import { useContext, useState } from "react";
import { DesktopContext } from "../contexts/DesktopContextProvider/DesktopContextProvider";
import ActionModal from "../components/common/ActionModal/ActionModal";
import UtilityModal from "../components/common/UtilityModal/UtilityModal";
import Image from "next/image";
import GrayLink from "../components/uiwrappers/GrayLink/GrayLink";
import { instagramToast } from "./utils";
import {
  INSTAGRAM_CONFIG_ID,
  INSTAGRAM_SCOPES,
} from "../components/primaryrender/connections/ConnectionsTab/constants";
import { api } from "../helpers/topline-api";

export function useAuthInstagram() {
  const { setIsInstagramAuthenticated, mutateSocialInfo } =
    useContext(DesktopContext);

  const [
    noProfessionalInstagramAccountErrorModal,
    setNoProfessionalInstagramAccountErrorModal,
  ] = useState(false);
  const [instagramLearnModal, setInstagramLearnModal] = useState(false);
  const [instagramConnectionErrorModal, setInstagramConnectionErrorModal] =
    useState(false);

  async function connectToInstagram(response) {
    try {
      console.log("get to connect to Instagram!");
      if (!router.query.code) {
        console.log("there is accessToken", response?.accessToken);
        if (!response?.accessToken) {
          setIsInstagramAuthenticated(false);
          setInstagramConnectionErrorModal(true);
          return "error";
        }

        const instUserAccessToken: string = response.accessToken;
        const prositeRes = await api.post(`/api/instagram-connect`, {
          user_access_token: instUserAccessToken,
        });

        if (
          prositeRes?.status === 400 &&
          prositeRes?.data?.error === "No Instagram account found"
        ) {
          setNoProfessionalInstagramAccountErrorModal(true);
          return "error";
        }
        console.log("what is prositeRes?.status", prositeRes?.status);
        mutateSocialInfo();
      }

      return "success";
    } catch (e) {
      setInstagramConnectionErrorModal(true);

      return "error";
    }
  }

  async function disconnectFromInstagram() {
    try {
      await api.delete(`/api/disconnect_instagram`);

      mutateSocialInfo();

      return "success";
    } catch (e) {
      console.log("error disconnecting instagram:", e);
      return "failure";
    }
  }

  const handleLogin = () => {
    FB.login(
      async function (response) {
        if (response?.authResponse && response?.status === "connected") {
          const status = await connectToInstagram(response.authResponse);
          instagramToast(status);
        } else {
          setInstagramConnectionErrorModal(true);
        }
      },
      { scope: INSTAGRAM_SCOPES, config_id: INSTAGRAM_CONFIG_ID }
    );
  };

  function renderInstagramErrorConnectionModal() {
    return (
      <>
        <ActionModal
          open={noProfessionalInstagramAccountErrorModal}
          setOpen={setNoProfessionalInstagramAccountErrorModal}
          header="You're almost there!"
          subheader="We need to go through a similar process one more time to complete the connection."
          primaryButtonText="Connect"
          icon="instagram-error"
          primaryButtonIcon="instagram"
          primaryButtonIconPlacement="leading"
          primaryButtonFunction={() => {
            handleLogin();
            setNoProfessionalInstagramAccountErrorModal(false);
          }}
          secondaryButtonFunction={() =>
            setNoProfessionalInstagramAccountErrorModal(false)
          }
        />
        <ActionModal
          open={instagramConnectionErrorModal}
          setOpen={setInstagramConnectionErrorModal}
          header="Unable to connect to Instagram"
          subheader="There was an error connecting your Instagram account, please select an option below."
          primaryButtonText="Try Again"
          icon="Delete"
          primaryButtonFunction={() => {
            handleLogin();
            setInstagramConnectionErrorModal(false);
          }}
          secondaryButtonFunction={() =>
            setInstagramConnectionErrorModal(false)
          }
          showBottomText={true}
          bottomText="Need help connecting?"
          bottomAnchorText="Contact us"
          bottomAnchorOnClick={() =>
            (window.location.href = `mailto:support@toplinepro.com?subject=Connecting Topline Pro to Instagram`)
          }
        />
        <UtilityModal
          isOpen={instagramLearnModal}
          setIsOpen={setInstagramLearnModal}
          showCancelButton={false}
          showSubmitButton={false}
          handleClose={() => setInstagramLearnModal(false)}
        >
          <div className="flex flex-col gap-4 sm:-mt-3 px-6 sm:w-500">
            <div className="flex flex-col items-center gap-2">
              <div className="flex items-center justify-center gap-6 mb-2">
                <Image
                  src="/instagram_logo.png"
                  width={32}
                  height={32}
                  alt="Instagram logo"
                />
                <Image
                  src="/facebook_logo.png"
                  width={32}
                  height={32}
                  alt="Facebook logo"
                />
              </div>
              <p className="text-body-medium">How can we help you?</p>
            </div>
            <div className="flex flex-col gap-2.5 items-center text-center">
              <GrayLink
                size="md"
                text="Change Instagram account type"
                icon="external-link"
                iconPlacement="lagging"
                onClickFunc={() => {
                  window.open(
                    "https://www.instagram.com/accounts/convert_to_professional_account/",
                    "_blank"
                  );
                }}
                customStyle="text-blue-600 text-body-medium hover:text-blue-800"
              />
              <GrayLink
                size="md"
                text="Link Instagram account to a Facebook Page"
                icon="external-link"
                iconPlacement="lagging"
                onClickFunc={() => {
                  window.open(
                    "https://help.instagram.com/570895513091465",
                    "_blank"
                  );
                }}
                customStyle="text-blue-600 text-body-medium hover:text-blue-800"
              />
            </div>
          </div>
          <hr className="my-8" />
          <div className="text-gray-700 text-xs-normal space-y-2 text-center">
            <h3 className="text-xs-medium">
              Why is this required for an Instagram connection?
            </h3>
            <p>
              Instagram requires you to have the following in order to connect:
            </p>
            <div className="flex justify-center">
              <ol className="list-decimal space-y-1 w-max text-left ml-6">
                <li>Your Instagram must be a Business or Creator account.</li>
                <li>Your Instagram must connected to a Facebook Page.</li>
              </ol>
            </div>
          </div>
        </UtilityModal>
      </>
    );
  }

  return {
    connectToInstagram,
    disconnectFromInstagram,
    renderInstagramErrorConnectionModal,
    handleLogin,
  };
}
