export default function RevertIconSolid({
  width = "w-4",
  height = "h-4",
  color = "text-gray-500",
}: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={`${width} ${height} ${color}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53001 2.47C9.67046 2.61062 9.74935 2.80125 9.74935 3C9.74935 3.19875 9.67046 3.38937 9.53001 3.53L4.81001 8.25H15C16.7902 8.25 18.5071 8.96115 19.773 10.227C21.0389 11.4929 21.75 13.2098 21.75 15C21.75 16.7902 21.0389 18.5071 19.773 19.773C18.5071 21.0388 16.7902 21.75 15 21.75H12C11.8011 21.75 11.6103 21.671 11.4697 21.5303C11.329 21.3897 11.25 21.1989 11.25 21C11.25 20.8011 11.329 20.6103 11.4697 20.4697C11.6103 20.329 11.8011 20.25 12 20.25H15C15.6895 20.25 16.3721 20.1142 17.0091 19.8504C17.6461 19.5865 18.2248 19.1998 18.7123 18.7123C19.1998 18.2248 19.5865 17.646 19.8504 17.0091C20.1142 16.3721 20.25 15.6894 20.25 15C20.25 14.3106 20.1142 13.6279 19.8504 12.9909C19.5865 12.3539 19.1998 11.7752 18.7123 11.2877C18.2248 10.8002 17.6461 10.4135 17.0091 10.1496C16.3721 9.88579 15.6895 9.75 15 9.75H4.81001L9.53001 14.47C9.6037 14.5387 9.6628 14.6215 9.70379 14.7135C9.74478 14.8055 9.76683 14.9048 9.7686 15.0055C9.77038 15.1062 9.75186 15.2062 9.71413 15.2996C9.67641 15.393 9.62027 15.4778 9.54905 15.549C9.47783 15.6203 9.393 15.6764 9.29961 15.7141C9.20622 15.7518 9.10619 15.7704 9.00549 15.7686C8.90479 15.7668 8.80547 15.7448 8.71347 15.7038C8.62147 15.6628 8.53867 15.6037 8.47001 15.53L2.47001 9.53C2.32956 9.38937 2.25067 9.19875 2.25067 9C2.25067 8.80125 2.32956 8.61062 2.47001 8.47L8.47001 2.47C8.61064 2.32955 8.80126 2.25066 9.00001 2.25066C9.19876 2.25066 9.38939 2.32955 9.53001 2.47Z"
        fill="currentColor"
      />
    </svg>
  );
}
