import React from "react";

interface GenericContainerProps {
  children: React.ReactNode;
  centeredContent?: boolean;
  customStyle?: string;
  dataCy?: string;
}

export default function GenericContainer({
  children,
  centeredContent,
  customStyle,
  dataCy,
}: GenericContainerProps) {
  let flexbox = "";

  if (centeredContent) flexbox = "flex items-center justify-center";

  return (
    <div
      className={`bg-white rounded-md ${customStyle} ${flexbox || ""}`}
      data-cy={dataCy ? dataCy : ""}
    >
      {children}
    </div>
  );
}
