export const INSTAGRAM_SCOPES =
  "public_profile,pages_read_user_content,pages_manage_posts,pages_manage_metadata,pages_read_engagement,pages_show_list,business_management,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,instagram_shopping_tag_products";

export const INSTAGRAM_CONFIG_ID =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
    ? "233540856144966"
    : process.env.NEXT_PUBLIC_INSTAGRAM_LOGIN_CONFIG_PROD;

// export const INSTAGRAM_CONFIG_ID =
//   process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
//     ? process.env.NEXT_PUBLIC_INSTAGRAM_LOGIN_CONFIG_TEST
//     : process.env.NEXT_PUBLIC_INSTAGRAM_LOGIN_CONFIG_PROD;
