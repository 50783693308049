import router from "next/router";
import { useContext, useState } from "react";
import { DesktopContext } from "../contexts/DesktopContextProvider/DesktopContextProvider";
import {
  getFbPageUrl,
  getLongLivedUserToken,
  getOtherFacebookTokens,
  updateWebsiteOnFacebook,
} from "../components/primaryrender/connections/ConnectionsTab/utils";
import { getFacebookProfileCoverPhotos } from "../components/primaryrender/editor/utils";
import { getNewFbReviews } from "../components/primaryrender/reviews/ReviewContainer/utils";
import { useTestimonials } from "./useTestimonalsInfo/useTestimonalsInfo";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import StackedCardsWithRadio from "../components/uiwrappers/StackedCardsWithRadio/StackedCardsWithRadio";
import ActionModal from "../components/common/ActionModal/ActionModal";
import { facebookToast } from "./utils";
import { api } from "../helpers/topline-api";

export function useAuthFacebook(slug: any) {
  const {
    setIsFacebookAuthenticated,
    profileInfo,
    basicInfo,
    setBasicInfo,
    facebookUserToken,
    setFacebookUserToken,
    setFacebookPageToken,
    setFacebookPageName,
    setFacebookPageId,
  } = useContext(DesktopContext);

  const [fbPagesObject, setFbPagesObject] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<string>("");
  const [multiplePages, setMultiplePages] = useState([]);
  const [showMultiplePagesModal, setShowMultiplePagesModal] = useState(false);

  const { mutateTestimonialInfo } = useTestimonials(profileInfo);

  async function connectToFacebook(response) {
    try {
      if (!router.query.code) {
        if (!response?.accessToken) {
          setIsFacebookAuthenticated(false);
          return "failure";
        }

        const fbUserAccessToken = response.accessToken;
        const fbUserTokenLong = await getLongLivedUserToken(
          slug,
          fbUserAccessToken
        );

        setFacebookUserToken(fbUserTokenLong);

        const fbPageResponseObject = await getOtherFacebookTokens(
          fbUserTokenLong
        );

        setFbPagesObject(fbPageResponseObject);

        if (fbPageResponseObject?.length > 1) {
          setMultiplePages(fbPageResponseObject);
          setShowMultiplePagesModal(true);
          setSelectedPage(fbPageResponseObject[0]?.id);
        } else if (fbPageResponseObject?.length === 1) {
          const pageInfo = fbPageResponseObject[0];
          syncFacebook(pageInfo, fbUserTokenLong);
        } else {
          facebookToast("failure");
        }
      }
    } catch (e) {
      facebookToast("failure");
    }
  }

  async function syncFacebook(pageInfo, fbUserAccessToken) {
    const fbPageAccessToken = pageInfo?.access_token;
    const fbPageId = pageInfo?.id;
    const fbPageName = pageInfo?.name;

    setFacebookPageName(fbPageName);

    if (fbPageAccessToken && fbPageId) {
      setIsFacebookAuthenticated(true);
    } else {
      setIsFacebookAuthenticated(false);
      return facebookToast("failure");
    }

    const fbTokens = {
      fbPageAccessToken,
      fbPageId,
      fbPageName,
      fbUserAccessToken,
    };

    if (fbTokens && fbPageId && fbPageAccessToken) {
      await updateDb(fbTokens, fbPageId, fbPageAccessToken);
      setFacebookPageToken(fbPageAccessToken);
      setFacebookPageId(fbPageId);
      setProfileCoverPhotos(fbPageId, fbPageAccessToken);
      await getNewFbReviews(basicInfo?.sitePk);
      await mutateTestimonialInfo();

      facebookToast("success");
    }
  }

  async function setProfileCoverPhotos(fbPageId, fbPageAccessToken) {
    const profilePhotoLocal = localStorage.getItem("profilePhoto");
    const coverPhotoLocal = localStorage.getItem("coverPhoto");
    const photos = await getFacebookProfileCoverPhotos(
      fbPageId,
      fbPageAccessToken
    );
    const profilePhoto = photos?.picture?.data.url;
    const coverPhoto = photos?.cover?.source;
    if (profilePhoto !== profilePhotoLocal || coverPhoto !== coverPhotoLocal) {
      localStorage.setItem("profilePhoto", profilePhoto);
      localStorage.setItem("coverPhoto", coverPhoto);
    }
  }

  async function updateDb(fbTokens, fbPageId, fbPageAccessToken) {
    const fbUrl = await getFbPageUrl(fbPageId, fbPageAccessToken);

    const updatedFbDataFromDBRes = await api.post(
      `/api/update-landing-page-on-connection-to-facebook/${slug}`,
      {
        fbTokens,
        fbUrl,
      }
    );

    const updatedFbDataFromDB = updatedFbDataFromDBRes.data.message;
    updateWebsiteOnFacebook(slug);

    setBasicInfo({
      ...basicInfo,
      ...updatedFbDataFromDB,
    });

    return updatedFbDataFromDB;
  }

  function renderFacebookButton(callbackFunction, customButton) {
    return (
      <FacebookLogin
        appId={
          process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
            ? process.env.NEXT_PUBLIC_FACEBOOK_APP_ID_TEST
            : process.env.NEXT_PUBLIC_FACEBOOK_APP_ID_PROD
        }
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile,pages_read_user_content,pages_manage_posts,pages_manage_metadata,pages_read_engagement,business_management"
        callback={callbackFunction}
        cssClass="login-facebook"
        disableMobileRedirect={false}
        isMobile={false}
        render={customButton}
      />
    );
  }

  function renderFacebookMultiPageModal() {
    return (
      <div>
        <ActionModal
          open={showMultiplePagesModal}
          setOpen={setShowMultiplePagesModal}
          header={`Which Facebook Page would you like to connect to ${profileInfo?.company_name}?`}
          primaryButtonText="Connect Facebook Page"
          primaryButtonFunction={() => {
            const pageInfo = fbPagesObject.find((p) => p.id === selectedPage);
            syncFacebook(pageInfo, facebookUserToken);
            setShowMultiplePagesModal(false);
          }}
          secondaryButtonText="Cancel"
          secondaryButtonFunction={() => {
            setShowMultiplePagesModal(false);
            setFbPagesObject([]);
          }}
          icon="facebook"
          secondIcon="fb-multiple-icon"
        >
          <div>
            <div className="text-sm-medium text-gray-500">Your Pages</div>
            <div className="pt-1 pb-3">
              <StackedCardsWithRadio
                options={multiplePages}
                selectedOption={selectedPage}
                setSelectedOption={setSelectedPage}
                defaultFirstSelected={true}
                title="Your Facebook Pages"
              />
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }

  return {
    connectToFacebook,
    renderFacebookMultiPageModal,
    renderFacebookButton,
  };
}
