import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import GenericContainer from "../../common/GenericContainer/GenericContainer";
import { arraysEqual } from "./utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export interface StackedCardsWithRadioProps {
  options: any[];
  selectedOption: string;
  setSelectedOption: (arg0: string) => void;
  defaultFirstSelected: boolean;
  title: string;
  error?: boolean;
  setError?: (arg0: boolean) => void;
  additionalElement?: any;
  additionalElementLocation?: number;
  handleSelectOption?: any;
}

export default function StackedCardsWithRadio({
  options,
  selectedOption,
  setSelectedOption,
  defaultFirstSelected,
  title,
  error,
  setError,
  additionalElement,
  additionalElementLocation,
  handleSelectOption,
}: StackedCardsWithRadioProps) {
  const [items, setItems] = useState(options);

  useEffect(() => {
    const areArraysEqual = arraysEqual(items, options);

    if (!areArraysEqual) {
      setItems(options);
    }
  }, [options]);

  const [selected, setSelected] = useState(
    defaultFirstSelected
      ? options[0]
      : options.find((o) => o.id === selectedOption)
  );

  async function onClickHandler(option: any) {
    setError && setError(false);
    setSelectedOption(option.id);
    setSelected(option);

    handleSelectOption && handleSelectOption(option.id);
  }

  return (
    <>
      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only"> {title} </RadioGroup.Label>
        <GenericContainer customStyle="-space-y-px">
          {items?.map((option, optionIdx) => (
            <RadioGroup.Option
              key={optionIdx}
              value={option}
              className={({ checked }) =>
                classNames(
                  error && "border-red-600",
                  optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  optionIdx === options.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  option?.name === selected?.name
                    ? "bg-blue-50 border-blue-200"
                    : "border-gray-200",
                  "relative border p-4 flex cursor-pointer focus:outline-none"
                )
              }
              onClick={() => onClickHandler(option)}
            >
              {({ active, checked }) => (
                <div>
                  <div className="flex flex-row">
                    <div className="flex">
                      <span
                        className={classNames(
                          checked
                            ? "bg-blue-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-blue-500" : "",
                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                    </div>
                    <span className="ml-3 flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-gray-900" : "text-gray-900",
                          "inline-block text-sm-medium"
                        )}
                      >
                        {option.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={classNames("text-gray-500 block text-sm")}
                      >
                        {option.description}
                      </RadioGroup.Description>
                    </span>
                  </div>
                  {additionalElement &&
                    additionalElementLocation &&
                    optionIdx === additionalElementLocation &&
                    checked && <div className="mt-4">{additionalElement}</div>}
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </GenericContainer>
      </RadioGroup>
    </>
  );
}
