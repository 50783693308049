import React, { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowCircleRightIcon,
  BellIcon,
  CheckCircleIcon,
  CheckIcon,
  FlagIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  RefreshIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import PrimaryButton from "../../uiwrappers/PrimaryButton/PrimaryButton";
import { ExclamationIcon } from "@heroicons/react/outline";
import SecondaryButton from "../../uiwrappers/SecondaryButton/SecondaryButton";
import RedButton from "../../uiwrappers/RedButton/RedButton";
import { useAuthFacebook } from "../../../hooks/useAuthFacebook";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { LinkIcon } from "@heroicons/react/outline";
import { facebookToast } from "../../../hooks/utils";
import { useAuthInstagram } from "../../../hooks/useAuthInstagram";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Image from "next/image";
import RisingArrowIcon from "../../primaryrender/billing/ProPlanTrialFeaturePopup/utils";

interface ActionModalProps {
  open: boolean;
  header?: any;
  headerSize?: any;
  setOpen: (arg0: boolean) => void;
  subheader?: string | ReactJSXElement;
  secondaryButtonText?: string;
  secondaryButtonIcon?: string;
  secondaryButtonIconPlacement?: string;
  secondaryButtonLoading?: boolean;
  primaryButtonText?: string;
  primaryButtonIcon?: string | null;
  primaryButtonIconPlacement?: string;
  primaryButtonColor?: any;
  customSecondaryButton?: any;
  danger?: boolean;
  icon?: string;
  secondIcon?: string;
  loading?: boolean;
  hideCancel?: boolean;
  showBottomText?: boolean;
  bottomText?: string;
  bottomAnchorText?: string;
  bottomAnchorOnClick?: () => void;
  subheaderAnchorText?: string;
  subheaderAnchorOnClick?: () => void;
  primaryCancel?: boolean;
  warning?: boolean;
  children?: any;
  facebook?: boolean;
  instagram?: boolean;
  primaryButtonFunction?: (reviewId: string) => void;
  secondaryButtonFunction?: () => void;
  secondaryButtonColor?: any;
  subHeaderLink?: any;
  isCustomCancelButtonChildrenPresent?: boolean;
  customCancelButtonChildren?: any;
  noButtons?: boolean;
  confetti?: boolean;
  hidePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  bottomLink?: any;
  heroImage?: string;
  dialogWidth?: string;
}

export default function ActionModal({
  open,
  setOpen,
  header,
  headerSize = "",
  subheader,
  secondaryButtonText = "Cancel",
  secondaryButtonIcon,
  secondaryButtonIconPlacement = "leading",
  secondaryButtonLoading,
  secondaryButtonFunction,
  customSecondaryButton,
  primaryButtonText = "Confirm",
  primaryButtonIcon = null,
  primaryButtonIconPlacement = "leading",
  primaryButtonFunction,
  primaryButtonColor = "blue",
  secondaryButtonColor = "white",
  icon,
  secondIcon,
  loading,
  hideCancel,
  showBottomText,
  bottomText,
  bottomAnchorText,
  bottomAnchorOnClick,
  subheaderAnchorText,
  subheaderAnchorOnClick,
  primaryCancel,
  subHeaderLink,
  children,
  facebook,
  instagram,
  noButtons = false,
  confetti,
  hidePrimaryButton = false,
  disableSecondaryButton = false,
  warning = false,
  bottomLink,
  heroImage,
  dialogWidth = "sm:max-w-lg",
}: ActionModalProps) {
  const { profileInfo } = useContext(DesktopContext);
  const [localLoading, setLocalLoading] = useState(false);

  const getIcon = () => {
    switch (icon) {
      case "connect":
        return (
          <div
            className={`bg-indigo-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <LinkIcon
              className="h-6 w-6 text-blue-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "refresh":
        return (
          <div
            className={`bg-indigo-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <RefreshIcon
              className="h-6 w-6 text-blue-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "flag":
        return (
          <div
            className={`bg-indigo-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <FlagIcon
              className="h-6 w-6 text-blue-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "sparkles":
        return (
          <div
            className={`bg-indigo-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <SparklesIcon
              className="h-6 w-6 text-blue-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "bell":
        return (
          <div
            className={`bg-indigo-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <BellIcon
              className="h-6 w-6 text-blue-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "Delete":
        return (
          <div
            className={`${
              primaryButtonColor === "blue" ? "bg-blue-100" : "bg-red-100"
            } mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <ExclamationIcon
              className={`h-6 w-6 flex-shrink-0 ${
                primaryButtonColor === "blue" ? "text-blue-600" : "text-red-500"
              }`}
              aria-hidden="true"
            />
          </div>
        );
      case "lock":
        return (
          <div
            className={`bg-blue-100 ${
              warning && "bg-amber-50"
            } mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <LockClosedIcon
              className={`h-6 w-6 text-blue-500 ${
                warning && "text-amber-400"
              } flex-shrink-0`}
              aria-hidden="true"
            />
          </div>
        );
      case "location":
        return (
          <div
            className={`bg-blue-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <LocationMarkerIcon
              className="h-6 w-6 text-blue-500 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "check":
        return (
          <div
            className={`bg-green-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <CheckIcon
              className="h-6 w-6 text-green-500 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "blue-check":
        return (
          <div
            className={`bg-indigo-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <CheckCircleIcon
              className="h-6 w-6 text-blue-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "green-check":
        return (
          <div
            className={`bg-green-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <CheckCircleIcon
              className="h-6 w-6 text-green-600 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "right-arrow":
        return (
          <div
            className={`bg-blue-100 mt-0 mb-3 rounded-full w-12 h-12 flex items-center justify-center mx-auto`}
          >
            <ArrowCircleRightIcon
              className="h-6 w-6 text-blue-500 flex-shrink-0"
              aria-hidden="true"
            />
          </div>
        );
      case "google":
        return (
          <div
            className={`my-3 rounded-full ${
              secondIcon ? "w-10 h-10" : "w-16 h-16"
            } flex items-center justify-center`}
          >
            <img src="/google-logo.png" alt="Google Logo" />
          </div>
        );
      case "facebook":
        return (
          <div
            className={`my-3 rounded-full ${
              secondIcon ? "w-10 h-10" : "w-16 h-16"
            } flex items-center justify-center`}
          >
            <img src="/facebook_logo.png" alt="Facebook Logo" />
          </div>
        );
      case "blue-star-icon":
        return (
          <img
            src="/BlueStarIcon.svg"
            alt="Blue Star Icon"
            className="h-12 w-full mb-2"
          />
        );
      case "rising-arrow":
        return (
          <div className="mb-2">
            <RisingArrowIcon />
          </div>
        );
      case "instagram-error":
        return (
          <div className="flex items-center justify-center gap-6 mb-2">
            <Image
              src="/instagram_logo.png"
              width={32}
              height={32}
              alt="Instagram logo"
            />
            <Image
              src="/facebook_logo.png"
              width={32}
              height={32}
              alt="Facebook logo"
            />
          </div>
        );
      default:
        <></>;
    }
  };

  function getHeaderSize() {
    if (!headerSize || headerSize === "normal") {
      return "text-body-medium";
    } else if (headerSize === "large") {
      return "text-h1-medium";
    }
  }

  const getSecondIcon = () => {
    switch (secondIcon) {
      case "fb-multiple-icon":
        return (
          <div
            className={`bg-blue-100 my-3 rounded-full w-10 h-10 flex items-center justify-center`}
          >
            <img src="/fb-multiple-icon.svg" alt="Facebook Multiple Icon" />
          </div>
        );
      default:
        return (
          <div
            className={`bg-blue-100 my-3 rounded-full w-10 h-10 flex items-center justify-center`}
          >
            <img src="/Topline-favicon.png" alt="Topline Logo" />
          </div>
        );
    }
  };

  const { link, text } = subHeaderLink || { link: "", text: "" };
  const { slug } = profileInfo || {};
  const {
    connectToFacebook,
    renderFacebookButton,
    renderFacebookMultiPageModal,
  } = useAuthFacebook(slug);

  const { handleLogin } = useAuthInstagram();

  function getConfirmButton() {
    if (facebook) {
      const callbackFunction = async (response) => {
        setLocalLoading(true);
        const status = await connectToFacebook(response);
        setLocalLoading(false);
        facebookToast(status);
      };
      const customButton = (renderProps) => (
        <PrimaryButton
          text={"Connect"}
          size="md"
          onClickFunc={() => {
            setOpen(false);
            renderProps.onClick();
          }}
          customStyle={`${hideCancel ? "w-full" : "sm:w-1/2 mb-3 sm:mb-0"}`}
          loading={localLoading}
          disabled={localLoading}
          cancel={primaryCancel}
        />
      );
      return renderFacebookButton(callbackFunction, customButton);
    } else if (instagram) {
      return (
        <PrimaryButton
          text="Connect"
          onClickFunc={() => {
            setOpen(false);
            handleLogin();
          }}
          size="md"
          customStyle={`${hideCancel ? "w-full" : "sm:w-1/2 mb-3 sm:mb-0"}`}
        />
      );
    }
    return (
      <>
        {primaryButtonColor === "blue" && (
          <PrimaryButton
            id="confirm-button"
            text={primaryButtonText}
            size="md"
            onClickFunc={primaryButtonFunction}
            customStyle={`${hideCancel ? "w-full" : "sm:w-1/2 sm:mb-0 mb-3"}`}
            loading={loading}
            disabled={loading}
            cancel={primaryCancel}
            icon={primaryButtonIcon}
            iconPlacement={primaryButtonIconPlacement}
          />
        )}
        {primaryButtonColor === "red" && (
          <RedButton
            id="confirm-button"
            text={primaryButtonText}
            size="md"
            onClickFunc={primaryButtonFunction}
            customStyle={`${hideCancel ? "w-full" : "sm:w-1/2 sm:mb-0 mb-3"}`}
            loading={loading}
            disabled={loading}
            cancel={primaryCancel}
          />
        )}
        {primaryButtonColor === "white" && (
          <SecondaryButton
            id="confirm-button"
            text={primaryButtonText}
            size="md"
            onClickFunc={primaryButtonFunction}
            customStyle={`${hideCancel ? "w-full" : "sm:w-1/2 sm:mb-0 mb-3"}`}
            loading={loading}
            disabled={loading}
            cancel={primaryCancel}
          />
        )}
      </>
    );
  }

  return open ? (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0"
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              unmount={true}
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 mx-4 sm:mx-0 sm:px-10 sm:py-6 text-left overflow-hidden shadow-xl w-full transform transition-all sm:align-middle ${dialogWidth} ${
                  confetti &&
                  "bg-meeting-goal-confetti bg-cover bg-no-repeat bg-center"
                }`}
              >
                <div className="flex justify-center space-x-6">
                  {heroImage && (
                    <div className="pb-4 -mx-4 -mt-5 sm:-mx-10 sm:-mt-6 pb-8">
                      <img src={heroImage} />
                    </div>
                  )}

                  {getIcon()}
                  {secondIcon && getSecondIcon()}
                </div>
                <div className="text-center">
                  <Dialog.Title
                    as="h1"
                    className={`${getHeaderSize()} text-gray-900 mb-3 sm:px-5`}
                  >
                    {header}
                  </Dialog.Title>
                </div>
                <div className={`${!noButtons && "mb-6"}`}>
                  <p className="text-body-normal text-gray-500 text-center">
                    {subheader}
                    <a href={link} className="text-blue-600">
                      {text}
                    </a>
                    {text && "."}
                    {subheaderAnchorText && (
                      <>
                        <span
                          className="ml-0.5 cursor-pointer font-medium text-blue-600 hover:text-blue-500 whitespace-nowrap"
                          onClick={subheaderAnchorOnClick}
                        >
                          {" "}
                          {subheaderAnchorText}
                        </span>
                        <span>.</span>
                      </>
                    )}
                  </p>
                </div>

                <div>{children}</div>

                {!noButtons && (
                  <div className="flex flex-col-reverse sm:flex-row w-full justify-center sm:mt-3">
                    {!hideCancel && (
                      <>
                        {!customSecondaryButton &&
                          secondaryButtonColor === "white" && (
                            <SecondaryButton
                              icon={
                                secondaryButtonLoading
                                  ? ""
                                  : secondaryButtonIcon
                              }
                              iconPlacement={secondaryButtonIconPlacement}
                              text={secondaryButtonText}
                              size="md"
                              onClickFunc={
                                secondaryButtonFunction
                                  ? secondaryButtonFunction
                                  : () => setOpen(false)
                              }
                              customStyle={`${
                                hidePrimaryButton
                                  ? "w-full"
                                  : "sm:w-1/2 sm:mr-4"
                              }`}
                              disabled={disableSecondaryButton}
                              loading={secondaryButtonLoading}
                            />
                          )}

                        {!customSecondaryButton &&
                          secondaryButtonColor === "blue" && (
                            <>
                              <PrimaryButton
                                text={secondaryButtonText}
                                size="md"
                                onClickFunc={() => setOpen(false)}
                                customStyle={`${
                                  hidePrimaryButton
                                    ? "w-full"
                                    : "sm:w-1/2 sm:mr-4"
                                }`}
                                icon={primaryButtonIcon}
                                iconPlacement={primaryButtonIconPlacement}
                              />
                            </>
                          )}

                        {customSecondaryButton && <>{customSecondaryButton}</>}
                      </>
                    )}

                    {!hidePrimaryButton && getConfirmButton()}
                  </div>
                )}

                {showBottomText && (
                  <p className="mt-3 text-center text-sm-normal text-gray-500">
                    {bottomText}
                    <span
                      className="mx-1 cursor-pointer text-sm-medium text-blue-600 hover:text-blue-500 whitespace-nowrap"
                      onClick={bottomAnchorOnClick}
                    >
                      {bottomAnchorText}
                    </span>
                  </p>
                )}

                {bottomLink && (
                  <div className="flex w-full justify-center pt-4">
                    {bottomLink}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  ) : (
    <></>
  );
}
