import React, { useContext, useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import SecondaryButton from "../../uiwrappers/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../uiwrappers/PrimaryButton/PrimaryButton";
import PrimaryLink from "../../uiwrappers/PrimaryLink/PrimaryLink";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import RedLink from "../../uiwrappers/RedLink/RedLink";
import GrayLink from "../../uiwrappers/GrayLink/GrayLink";
import { fixHeightOfBody } from "./utils";
import useIsMobile from "../../../hooks/useIsMobile";

interface UtilityModalProps {
  isOpen: boolean;
  setIsOpen: (_isOpen: boolean) => void;
  title?: any;
  showCancelButton?: boolean;
  secondaryButtonText?: string;
  onCancelHandler?: React.MouseEventHandler<HTMLButtonElement>;
  showSubmitButton?: boolean;
  isSubmitDisabled?: boolean;
  onSubmitHandler?: () => void | Promise<void>;
  submitButtonId?: string;
  submitButtonText?: string;
  subTitle?: string;
  children?: React.ReactNode;
  showLoader?: boolean;
  headerActionButton?: boolean;
  headerActionButtonText?: string;
  headerActionButtonFunction?: React.MouseEventHandler<HTMLButtonElement>;
  headerActionButtonColor?: string;
  headerActionButtonIcon?: string;
  loading?: boolean;
  fullWidth?: boolean;
  disabledActionButton?: boolean;
  hideXIcon?: boolean;
  primaryButtonLoading?: boolean;
  elementStickyToBottom?: JSX.Element | null;
  elementStickyToTop?: JSX.Element | null;
  headerBorder?: boolean;
  footerBorder?: boolean;
  width?: string;
  height?: string;
  customButtons?: JSX.Element;
  headerElement?: JSX.Element;
  maxHeight?: string;
  footerBorderMobile?: boolean;
  modalBodyBackground?: string;
  handleClose?: () => void;
  customOverflow?: string;
  disablePadding?: boolean;
  elementInlineWithButtons?: JSX.Element | null;
}

export default function UtilityModal({
  isOpen,
  setIsOpen,
  title,
  subTitle,
  children,
  showCancelButton = true,
  onCancelHandler,
  secondaryButtonText,
  showSubmitButton = true,
  isSubmitDisabled,
  onSubmitHandler,
  submitButtonId,
  submitButtonText,
  showLoader,
  headerActionButton,
  headerActionButtonText,
  headerActionButtonFunction,
  headerActionButtonColor,
  headerActionButtonIcon,
  loading,
  disabledActionButton,
  hideXIcon,
  primaryButtonLoading,
  elementStickyToBottom,
  elementStickyToTop,
  headerBorder = false,
  width,
  height,
  customButtons,
  footerBorder,
  headerElement,
  maxHeight,
  footerBorderMobile = false,
  modalBodyBackground,
  handleClose,
  customOverflow,
  disablePadding,
  elementInlineWithButtons,
}: UtilityModalProps): React.ReactElement {
  const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
  const { downloadAppOnIphoneWithNoHomeButton } = useContext(DesktopContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    fixHeightOfBody(isMobile, isOpen);
    return () => {
      document.body.style.height = "";
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.touchAction = "";
    };
  }, [isOpen, isMobile]);

  const onSubmitHandlerWrapper = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (isSubmitProcessing) return;
    setIsSubmitProcessing(true);
    try {
      onSubmitHandler && (await onSubmitHandler());
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsSubmitProcessing(false);
    }
  };

  function closeModal() {
    const targetElement = event?.target as Element;
    const toastClicked = targetElement?.closest(".Toastify");
    if (toastClicked) {
      return;
    }

    if (handleClose) {
      handleClose();
    } else {
      setIsOpen(false);
    }
  }

  return isOpen ? (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="z-40 fixed inset-0 sm:h-auto sm:overflow-hidden"
        onClose={closeModal}
      >
        <div className="flex min-h-screen sm:h-auto text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            unmount={true}
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-0"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-0 translate-y-0 sm:scale-100"
            leaveTo="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* pointer-events-none fixed transform sm:translate-y-0 lg:top-0 right-0 flex h-full sm:pb-0 max-w-full sm:pl-10"> */}
            <div
              id="full-screen-modal-container"
              data-cy="full-screen-modal-container"
              className={`flex flex-col z-40 fixed w-full h-full sm:relative sm:inline-block align-bottom sm:rounded-lg text-left shadow-xl sm:align-middle sm:p-6 bg-gray-50 ${
                maxHeight ? maxHeight : "sm:max-h-screen"
              } ${width ? width : "sm:w-auto sm:max-w-full"} ${
                height ? height : "sm:h-auto"
              } ${customOverflow ? customOverflow : "sm:overflow-visible"}`}
            >
              <div
                id="full-screen-modal-header"
                className="flex w-full items-center p-6 sm:px-0 sm:pt-0 sm:flex-row-reverse border-b sm:border-none"
              >
                {hideXIcon && <div className="h-7 w-7 sm:hidden"></div>}
                {!hideXIcon && (
                  <button
                    type="button"
                    className="flex items-center justify-center focus:outline-none close-details-button rounded-md text-gray-900 hover:text-gray-500"
                    onClick={handleClose ? handleClose : onCancelHandler}
                  >
                    <XIcon
                      className="h-6 w-6 hidden sm:block"
                      aria-hidden="true"
                    />
                    <ChevronLeftIcon
                      className="sm:hidden focus:border-none flex h-7 w-7 text-gray-900 cursor-pointer"
                      aria-hidden="true"
                    />
                  </button>
                )}

                {headerActionButton &&
                  headerActionButtonText &&
                  headerActionButtonColor === "gray" && (
                    <GrayLink
                      text={headerActionButtonText}
                      icon={headerActionButtonIcon}
                      size="lg"
                      onClickFunc={headerActionButtonFunction}
                      customStyle="sm:hidden text-body-medium absolute right-6 top-6 transform translate-y-0.5"
                      saveDisabled={disabledActionButton}
                    />
                  )}
                {headerActionButton &&
                  headerActionButtonText &&
                  headerActionButtonColor === "red" && (
                    <RedLink
                      text={headerActionButtonText}
                      icon={headerActionButtonIcon}
                      size="lg"
                      onClickFunc={headerActionButtonFunction}
                      customStyle="sm:hidden text-body-medium absolute right-6 top-6 transform translate-y-0.5"
                      saveDisabled={disabledActionButton}
                    />
                  )}
                {headerActionButton &&
                  headerActionButtonText &&
                  headerActionButtonColor === "blue" && (
                    <PrimaryLink
                      text={headerActionButtonText}
                      icon={headerActionButtonIcon}
                      size="lg"
                      onClickFunc={headerActionButtonFunction}
                      loading={loading}
                      customStyle="sm:hidden text-body-medium absolute right-6 top-6 transform translate-y-0.5"
                      saveDisabled={disabledActionButton}
                    />
                  )}

                <div className="w-full">
                  {!headerElement && (
                    <Dialog.Title
                      as="h3"
                      className="text-h3-medium text-center sm:text-left text-gray-900 whitespace-nowrap"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  {headerElement && <>{headerElement}</>}

                  {subTitle && (
                    <div className="mt-2 mb-6">
                      <p className="text-gray-500 text-center sm:text-left">
                        {subTitle}
                      </p>
                    </div>
                  )}
                </div>

                <div className="h-7 w-7 sm:hidden"></div>
              </div>

              {elementStickyToTop && (
                <div className="flex w-full p-4 pb-0 sm:p-0">
                  {elementStickyToTop}
                </div>
              )}

              {headerBorder && <hr className="hidden sm:block sm:-mx-6" />}

              <div
                className={`flex flex-col flex-grow h-full sm:p-0 w-full overflow-y-auto sm:overflow-visible ${
                  disablePadding ? "p-0" : "p-4"
                } ${modalBodyBackground ? modalBodyBackground : ""}`}
              >
                {children}
              </div>

              {footerBorder && (
                <hr
                  className={`${
                    footerBorderMobile ? "block" : "hidden"
                  } sm:block sm:-mx-6`}
                />
              )}

              {elementStickyToBottom && (
                <div className="flex w-full p-4 sm:p-0">
                  {elementStickyToBottom}
                </div>
              )}

              {/* mobile */}
              {(showCancelButton || showSubmitButton) && (
                <div>
                  <div
                    className={`sm:hidden  p-3 justify-end align-middle items-center border-t ${
                      downloadAppOnIphoneWithNoHomeButton ? "pb-8" : ""
                    }`}
                  >
                    {elementInlineWithButtons && (
                      <div className="text-center mb-3">
                        {elementInlineWithButtons}
                      </div>
                    )}
                    <div className="flex w-full">
                      {showCancelButton && (
                        <div className="w-full h-full flex align-middle items-center justify-center">
                          <SecondaryButton
                            data-cy="full-screen-modal-cancel-button"
                            text={secondaryButtonText || "Cancel"}
                            size="md"
                            onClickFunc={onCancelHandler}
                            customStyle={`${
                              !showSubmitButton ? "w-screen" : "w-full"
                            } mr-3`}
                          />
                        </div>
                      )}
                      <div className="w-full h-full flex items-center justify-center ">
                        {showSubmitButton && (
                          <>
                            <PrimaryButton
                              data-cy="full-screen-modal-submit-button"
                              id={submitButtonId}
                              text={submitButtonText || "Submit"}
                              size="md"
                              disabled={isSubmitDisabled}
                              onClickFunc={onSubmitHandlerWrapper}
                              customStyle="w-full"
                              loading={
                                primaryButtonLoading
                                  ? primaryButtonLoading
                                  : showLoader && isSubmitProcessing
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* desktop */}
              {(showCancelButton || showSubmitButton) && (
                <div className=" w-full h-full hidden sm:flex sm:justify-end align-middle items-center mt-4">
                  {elementInlineWithButtons && (
                    <div className="w-full">{elementInlineWithButtons}</div>
                  )}
                  {showCancelButton && (
                    <SecondaryButton
                      id={"full-screen-modal-cancel-button"}
                      text={secondaryButtonText || "Cancel"}
                      size="md"
                      onClickFunc={onCancelHandler}
                      customStyle={`${!showSubmitButton ? "w-screen" : "mr-2"}`}
                    />
                  )}
                  {showSubmitButton && (
                    <PrimaryButton
                      id={submitButtonId || "full-screen-modal-submit-button"}
                      text={submitButtonText || "Submit"}
                      size="md"
                      loading={
                        primaryButtonLoading
                          ? primaryButtonLoading
                          : showLoader && isSubmitProcessing
                      }
                      disabled={isSubmitDisabled}
                      onClickFunc={onSubmitHandlerWrapper}
                      customStyle="whitespace-nowrap"
                    />
                  )}
                </div>
              )}

              {customButtons && <>{customButtons}</>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  ) : (
    <></>
  );
}
