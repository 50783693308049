import {
  ExternalLinkIcon,
  UploadIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  MailIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export interface RedButtonProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  alignment?: string;
  text: string;
  size: string;
  onClickFunc?: any;
  width?: string;
  height?: string;
  spacing?: string;
  type?: "button" | "reset" | "submit";
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  warning?: boolean;
  cancel?: boolean;
  icon?: any;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
}

export default function RedButton({
  text,
  size,
  onClickFunc,
  disabled,
  loading,
  warning,
  cancel,
  icon,
  iconPlacement,
  id,
  customStyle,
}: RedButtonProps) {
  const spinnerSize = size;
  let style;
  let cssStyle;
  let textSize;

  if (loading) {
    style = "bg-red-800 text-white border border-transparent";
  } else if (disabled) {
    style = "opacity-50 bg-red-900 text-white cursor-not-allowed";
  } else if (warning) {
    style = "bg-red-800 text-white border border-transparent";
  } else if (cancel) {
    style = "bg-white text-white border border-transparent";
  } else {
    style =
      "bg-red-600 text-white border border-transparent hover:bg-red-800 active:ring-2 active:ring-offset-2 active:ring-red-800 focus:bg-red-600 transition-colors duration-150 ease-in-out";
  }

  let iconStyle = `text-white`;
  function getIconStyle(size: string) {
    if (size === "xs" || size === "sm")
      return `h-4 w-4 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-2" : "ml-2"
      }`;

    if (size === "md")
      return `h-5 w-5 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-2" : "ml-2"
      }`;
    else
      return `h-5 w-5 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-3" : "ml-3"
      }`;
  }

  switch (size) {
    case "xs":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "9px" : "11px",
        paddingRight: icon && iconPlacement === "lagging" ? "9px" : "11px",
        paddingTop: "5px",
        paddingBottom: "5px",
      };
      iconStyle = getIconStyle("xs");
      break;
    case "sm":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "11px" : "13px",
        paddingRight: icon && iconPlacement === "lagging" ? "11px" : "13px",
        paddingTop: "7px",
        paddingBottom: "7px",
      };
      iconStyle = getIconStyle("sm");
      break;
    case "md":
      textSize = "text-sm";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "15px" : "17px",
        paddingRight: icon && iconPlacement === "lagging" ? "15px" : "17px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("md");
      break;
    case "lg":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "15px" : "17px",
        paddingRight: icon && iconPlacement === "lagging" ? "15px" : "17px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("lg");
      break;
    case "xl":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "23px" : "25px",
        paddingRight: icon && iconPlacement === "lagging" ? "23px" : "25px",
        paddingTop: "13px",
        paddingBottom: "13px",
      };
      iconStyle = getIconStyle("xl");
      break;
  }

  if (icon) {
    switch (icon) {
      case "chat":
        icon = <ChatIcon className={iconStyle} />;
        break;
      case "mail":
        icon = <MailIcon className={iconStyle} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={iconStyle} />;
        break;
      case "plus":
        icon = <PlusIcon className={iconStyle} />;
        break;
      case "delete":
        icon = <TrashIcon className={iconStyle} />;
        break;
      case "upload":
        icon = <UploadIcon className={iconStyle} />;
        break;
      case "export":
        icon = <DownloadIcon className={iconStyle} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={iconStyle} />;
        break;
    }
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  return (
    <>
      <button
        id={id || ""}
        type="button"
        data-cy={id}
        disabled={disabled}
        className={`${textSize} ${style} flex justify-center items-center rounded-full font-medium shadow focus:outline-none`}
        style={cssStyle}
        onClick={onClickFunc}
      >
        {iconPlacement === "leading" && icon}
        {loading ? (
          <LoadingSpinner
            size={
              spinnerSize === "lg" || spinnerSize === "xl" ? "big-spinner" : ""
            }
          />
        ) : (
          text
        )}
        {iconPlacement === "lagging" && icon}
      </button>
    </>
  );
}
